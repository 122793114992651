<template>
  <div class="height-100 max_box">
    <div class="audio-box">
      <audio src="audio/AVeryHappyChristmas.mp3" ref="bgAudio"></audio>
      <audio src="audio/银华心怡灵活配置混合_基金信息.mp3" ref="audio1"></audio>
      <audio
        src="audio/银华心怡灵活配置混合_收益率走势.mp3"
        ref="audio2"
      ></audio>
      <audio
        src="audio/银华心怡灵活配置混合-收益倍数.mp3"
        ref="audio2_1"
      ></audio>
      <audio
        src="audio/银华心怡灵活配置混合-同类基金排名.mp3"
        ref="audio3_2"
      ></audio>
      <audio
        src="audio/银华心怡灵活配置混合-同类平均年化回报.mp3"
        ref="audio3"
      ></audio>
      <audio src="audio/huaan4.mp3" ref="audio4"></audio>
      <audio
        src="audio/银华心怡灵活配置混合-基金收益能力.mp3"
        ref="audio5"
      ></audio>
      <audio
        src="audio/银华心怡灵活配置混合--持仓情况.mp3"
        ref="audio6"
      ></audio>
      <audio src="audio/huaan7.mp3" ref="audio7"></audio>
      <audio src="audio/基金经理.mp3" ref="audio8"></audio>
      <audio src="audio/运作指数.mp3" ref="audio9"></audio>
      <audio
        src="audio/银华心怡灵活配置混合-近一年最大回撤.mp3"
        ref="audio10"
      ></audio>
      <audio
        src="audio/银华心怡灵活配置混合--十大重仓股.mp3"
        ref="audio11"
      ></audio>
      <audio src="audio/风险.mp3" ref="audio12"></audio>
    </div>
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="card1_top_box">
        <p class="font-blod">银华心怡灵活配置混合</p>
        <p>005794</p>
        <div>
          <div>
            <p>成立日期</p>
            <p>2018-07-05</p>
          </div>
          <div>
            <p>基金类型</p>
            <p>灵活配置型</p>
          </div>
          <div>
            <p>风险等级</p>
            <p>中高风险</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">收益走势</p>
          <p class="title_r">
            <span>银华心怡灵活配置混合</span><span>沪深300</span>
          </p>
        </div>
        <div class="content_box relative">
          <div id="chart1" class="chart"></div>
          <div class="ab_center content_box_p none">
            <p><span>3年</span>净值增长</p>
            <p><span class="large">2.9</span>倍</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_main card3_cardmain">
        <div class="title">
          <p class="title_l">业绩表现</p>
          <div class="card3_des">
            <div class="font-16 title_des1">
              <span>同期同类年化回报</span
              ><span class="color_EF622E card3_num">25.2%</span>
            </div>
            <div class="font-16 title_des2 none">
              <span>本基金年化回报</span
              ><span class="color_EF622E card3_num">58.53%</span>
            </div>
            <div class="font-16 title_des3 none">
              <span>超过同类平均</span
              ><span class="color_EF622E card3_num">33.3%</span>
            </div>
          </div>
        </div>
        <div class="content_box relative">
          <div id="chart2" class="chart"></div>
          <div class="ab_center card3_abcenter relative none">
            <p class="card3_p1 none">
              <span>近3年年化回报在同类型基金中排名</span>
            </p>
            <p class="color_EF622E card3_p2 none">
              <span class="large">TOP43</span>
            </p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="card_main">
        <div class="content_box relative">
          <div class="move_1">
            <p>这只产品究竟怎么样?</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金收益能力</p>
        </div>
        <div class="content_box">
          <div>
            <p>近1年收益率</p>
            <p><span id="num2">50.86</span>%</p>
          </div>
          <div>
            <p>同类基金对比</p>
            <p><span class="compare">超</span><span>90</span>%</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金持仓情况</p>
        </div>
        <div class="content_box relative">
          <div id="chart3" class="chart"></div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="card_main">
        <div class="content_box relative">
          <div class="move_3">
            <p>基金经理表现如何?</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理</p>
        </div>
        <div class="content_box">
          <div class="flex align-center">
            <p><img src="../assets/img/p2.png" alt="" /></p>
            <div class="flex1">
              <p class="flex flex-between font-24 margin-l-8 name_box">
                <span>李晓星</span><span><i id="num1"></i>%</span>
              </p>
              <div class="flex flex-between margin-l-8 des_box">
                <p>
                  <span>硕士</span>
                  <span class="margin-l-8 margin-r-8">|</span>
                  <span>银华基金</span>
                </p>
                <p>主动偏股型年化回报</p>
              </div>
            </div>
          </div>
          <div class="flex flex-between label_box">
            <div>
              <p>任职年限</p>
              <p>6.74年</p>
            </div>
            <div class="margin-l-8 margin-r-8">
              <p>管理规模</p>
              <p>512.19亿</p>
            </div>
            <div>
              <p>重仓行业</p>
              <p>科技业</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card9"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理运作指数<span>（任职以来）</span></p>
          <!-- <p class="title_r">
            <span>基金经理</span><span>同类平均</span><span>沪深300</span>
          </p> -->
        </div>
        <div class="content_box">
          <div id="chart4" class="chart"></div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card10"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金抗跌能力</p>
          <p class="font-16">近一年</p>
        </div>
        <div class="content_box">
          <div>
            <p>最大回撤</p>
            <p><span id="num3">18.02</span>%</p>
          </div>
          <div>
            <p>同类排名（共2685支）</p>
            <p>NO.18</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card11"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">十大重仓股</p>
        </div>
        <div class="content_box">
          <div id="chart5" class="chart"></div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card12"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">风险提示</p>
        </div>
        <div class="content_box relative">
          <div class="font-16 text-center">
            <p>上述内容仅供投资者参考，不构成投资建议，据此操作，风险自担。</p>
            <p>
              基金的过往业绩并不预示其未来表现，基金管理人管理的其他基金的业绩并
              不构成基金业绩表现的保证。
            </p>
            <p>
              请您认真阅读《基金合同》等法律文件，结合自身风险承受能力选择合适的产品。
            </p>
            <p>市场有风险，投资需谨慎。</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div
      class="card_heng2 animate__animated animate__fadeIn duration none"
      id="card13"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/huaanend.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams } from "../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template102",
  data() {
    return {
      device_id: null,
      model_id: 102,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      chart2: {
        x: ["银华心怡", "同类平均", "沪深300", "业绩比较基准"],
        y: [58.53, 25.2, 11.09, 10.21],
      },
      chart3: [
        { value: 69.91, name: "制造业" },
        { value: 7.77, name: "租赁和商务服务业" },
        { value: 6.01, name: "消费者非必需品" },
        { value: 16.31, name: "其他" },
      ],
      chart5: [
        {
          name: "宁德时代",
          value: 9.22,
        },
        {
          name: "汇川技术",
          value: 8.08,
        },
        {
          name: "分众传媒",
          value: 7.77,
        },
        {
          name: "隆基股份",
          value: 5.79,
        },
        {
          name: "通威股份",
          value: 5.62,
        },
        {
          name: "晶澳科技",
          value: 5.37,
        },
        {
          name: "韦尔股份",
          value: 5.31,
        },
        {
          name: "赣锋锂业",
          value: 4.95,
        },
        {
          name: "银河娱乐",
          value: 4.86,
        },
        {
          name: "科达利",
          value: 4.33,
        },
      ],
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    if (this.device_id) {
      setTimeout(() => {
        ApiServe.recordStart({
          model_id: this.model_id,
          device_id: this.device_id,
          record_time: 96,
          real: 1,
        }).then((res) => {
          this.load();
        });
      }, 5000);
    } else {
      setTimeout(() => {
        this.load();
      }, 1000);
    }
  },
  methods: {
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 2, this.options);
      num.start();
    },
    // 收益走势
    loadChart1(color1 = "#EF622E", color2 = "#7E8090", isShow = true) {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      var option = {
        xAxis: {
          type: "category",
          data: [
            "2018/07",
            "2018/11",
            "2019/02",
            "2019/08",
            "2021/01",
            "2021/05",
            "2021/09",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
          },
        },
        grid: {
          left: 19,
          bottom: 30,
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(222, 223, 230, 0.4)",
            },
          },
        },
        series: [
          {
            data: [100, 120, 110, 160, 180, 170, 240],
            type: "line",
            color: color1,
            showSymbol: false,
            endLabel: {
              show: isShow,
              offset: [-50, -20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#EF622E",
              formatter: function (params) {
                return "298.45%";
              },
              fontSize: 16,
            },
            lineStyle: {
              width: 4,
            },
          },
          {
            data: [80, 100, 90, 120, 160, 150, 180],
            type: "line",
            color: color2,
            showSymbol: false,
            endLabel: {
              show: isShow,
              offset: [-50, 20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#7E8090",
              formatter: function (params) {
                return "51.91%";
              },
              fontSize: 16,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 业绩表现--柱状图
    loadChart2() {
      const colors = ["#EF622E", "#FFBB81", "#38A8FA", "#7E8090"];
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      var option = {
        grid: {
          left: 10,
          bottom: 30,
          right: 10,
        },
        xAxis: {
          type: "category",
          data: this.chart2.x,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
            interval: 0,
          },
        },
        animationDuration: 4000,
        yAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(222, 223, 230, 0.4)",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
              formatter: function ({ value }) {
                return value + "%";
              },
              color: "inherit",
            },
            data: this.chart2.y,
            itemStyle: {
              color: function (val) {
                return colors[val.dataIndex];
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金持仓情况
    loadChart3() {
      var myChart = this.$echarts.init(document.getElementById("chart3"));
      var option = {
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            startAngle: 190,
            minAngle: 30,
            animationDuration: 2000,
            label: {
              color: "inherit",
              formatter: function ({ value, name }) {
                return name + "\n\n" + value + "%";
              },
            },
            data: this.chart3,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#EF622E", "#C3A178", "#5B72B1", "#aaa"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金经理运作指数
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      var option = {
        xAxis: {
          type: "category",
          data: [
            "2018/07",
            "2018/11",
            "2019/02",
            "2019/08",
            "2021/01",
            "2021/05",
            "2021/09",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
          },
        },
        grid: {
          left: 10,
          bottom: 30,
        },
        legend: {
          data: ["银华心怡灵活配置", "同类平均", "沪深300"],
          left: 0,
        },
        animationDuration: 3000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(222, 223, 230, 0.4)",
            },
          },
        },
        series: [
          {
            data: [120, 140, 160, 180, 220, 280, 360],
            type: "line",
            name: "银华心怡灵活配置",
            color: "#EF622E",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-50, -20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#EF622E",
              formatter: function (params) {
                return "163.87%";
              },
              fontSize: 16,
            },
            lineStyle: {
              width: 4,
            },
          },
          {
            data: [90, 110, 140, 150, 180, 170, 240],
            type: "line",
            name: "同类平均",
            color: "#C3A178",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-50, 0],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#C3A178",
              formatter: function (params) {
                return "76.24%";
              },
              fontSize: 16,
            },
          },
          {
            data: [90, 100, 70, 86, 100, 140, 180],
            type: "line",
            name: "沪深300",
            color: "#7E8090",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-50, 20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#7E8090",
              formatter: function (params) {
                return "51.91%";
              },
              fontSize: 16,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 十大重仓持股
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      // 指定图表的配置项和数据
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "treemap",
            data: this.chart5,
            left: 9,
            top: 9,
            bottom: 9,
            right: 9,
            //animationDuration: 4000,
            breadcrumb: {
              show: false,
            },
            levels: [
              {
                // color: [
                //   "RGBA(239, 98, 46, 1)",
                //   "RGBA(239, 98, 46, 0.95)",
                //   "RGBA(239, 98, 46, 0.9)",
                //   "RGBA(239, 98, 46, 0.85)",
                //   "RGBA(239, 98, 46, 0.8)",
                //   "RGBA(239, 98, 46, 0.75)",
                //   "RGBA(239, 98, 46, 0.7)",
                //   "RGBA(239, 98, 46, 0.65)",
                //   "RGBA(239, 98, 46, 0.6)",
                //   "RGBA(239, 98, 46, 0.55)",
                //   "RGBA(239, 98, 46, 0.5)",
                // ],
                color: ["#EF622E"],
              },
            ],
            label: {
              show: true,
              color: "#fff",
              formatter: function (value) {
                return value.name + "\n" + "\n" + value.value + "%";
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.audio1.play();
      // this.$refs.video1.play(); //首页的视频播放
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 3000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.loadChart1();
        this.$refs.audio2.play();
      }, 4000); //间隔
      setTimeout(() => {
        $(".content_box_p").fadeIn("slow");
      }, 9000);
      setTimeout(() => {
        this.$refs.audio2_1.play();
      }, 9500);
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, 11500);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart2();
        this.$refs.audio3.play();
      }, 12500); //间隔
      setTimeout(() => {
        $(".title_des1").css("display", "none");
        $(".title_des2").fadeIn("slow");
      }, 15500);
      setTimeout(() => {
        $(".title_des2").css("display", "none");
        $(".title_des3").fadeIn("slow");
      }, 18000);
      setTimeout(() => {
        $(".card3_abcenter").css("display", "block");
        $(".card3_abcenter").animate({ right: "0px" }, 2000);
      }, 22000);
      setTimeout(() => {
        $(".card3_p1").fadeIn("slow");
        $(".card3_p2").fadeIn("slow");
        this.$refs.audio3_2.play();
      }, 24000);
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, 28000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        $(".move_1").animate({ left: "50%", top: "50%" }, 1000);
        this.$refs.audio4.play();
      }, 29000); //间隔
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, 31000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.startNumber("num2", 50.86, 2);
        this.$refs.audio5.play();
      }, 32000); //间隔
      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, 39000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.loadChart3();
        this.$refs.audio6.play();
      }, 40000); //间隔
      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, 49000);
      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        $(".move_3").animate({ left: "50%", top: "50%" }, 1000);
        this.$refs.audio7.play();
      }, 50000); //间隔
      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, 52000);
      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.startNumber("num1", 163.87, 2);
        this.$refs.audio8.play();
      }, 53000); //间隔
      setTimeout(() => {
        $("#card8").addClass("animate__fadeOut");
      }, 58000);
      setTimeout(() => {
        $("#card8").addClass("none");
        $("#card9").removeClass("none");
        this.loadChart4();
        this.$refs.audio9.play();
      }, 59000); //间隔
      setTimeout(() => {
        $("#card9").addClass("animate__fadeOut");
      }, 65000);
      setTimeout(() => {
        $("#card9").addClass("none");
        $("#card10").removeClass("none");
        this.startNumber("num3", 18.02, 2);
        this.$refs.audio10.play();
      }, 66000); //间隔
      setTimeout(() => {
        $("#card10").addClass("animate__fadeOut");
      }, 72000);
      setTimeout(() => {
        $("#card10").addClass("none");
        $("#card11").removeClass("none");
        this.loadChart5();
        this.$refs.audio11.play();
      }, 73000); //间隔
      setTimeout(() => {
        $("#card11").addClass("animate__fadeOut");
      }, 78000);
      setTimeout(() => {
        $("#card11").addClass("none");
        $("#card12").removeClass("none");
        this.$refs.audio12.play();
      }, 79000); //间隔)
      setTimeout(() => {
        $("#card12").addClass("animate__fadeOut");
      }, 90000);
      setTimeout(() => {
        $("#card12").addClass("none");
        $("#card13").removeClass("none");
        this.$refs.endVideo.play();
      }, 91000); //间隔
      // 总时间 91 + 5秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: this.model_id,
            device_id: this.device_id,
            real: 1,
          });
        }, 104000);
      }
    },
  },
};
</script>
<style scoped lang="less">
.max_box {
  background: url("../assets/img/bg.png") no-repeat center center;
  background-size: cover;
  font-size: 12px;
}
.card {
  color: #7e8090;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 12px;
  background: transparent;
  overflow: hidden;
}
.color_EF622E {
  color: #ef622e;
}
.chart {
  height: 100%;
  width: 100%;
}
.tips {
  position: absolute;
  bottom: 153px;
  left: 10px;
  right: 10px;
  color: #878998;
  text-align: center;
}
.card_main {
  position: absolute;
  top: 226px;
  left: 16px;
  right: 16px;
  bottom: 0;
}
.ab_center {
  position: absolute;
  left: 19px;
  right: 20px;
  top: 23px;
  bottom: 28px;
  background: rgba(231, 232, 242, 0.9);
}
.content_box {
  height: 243px;
  box-shadow: 0px 4px 8px rgba(126, 128, 144, 0.16);
  border-radius: 8px;
  border: 2px solid #f2f3f7;
  margin-top: 11px;
  background: rgba(255, 255, 255, 1);
  .content_box_p {
    > p:nth-of-type(1) {
      font-size: 16px;
      font-weight: bold;
      color: #7e8090;
    }
    > p:nth-of-type(2) {
      font-size: 16px;
      font-weight: bold;
      color: #ef622e;
      height: 80px;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 30px;
  .title_l {
    height: 30px;
    font-size: 21px;
    color: #7e8090;
    font-weight: bold;
  }
  .title_r {
    height: 30px;
    > span:nth-of-type(1) {
      color: #ef622e;
      margin-right: 20px;
    }
    > span:nth-of-type(1)::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      margin-bottom: 4px;
      margin-right: 4px;
      background: #ef622e;
    }
    > span:nth-of-type(2) {
      color: #7e8090;
    }
    > span:nth-of-type(2)::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      margin-bottom: 4px;
      margin-right: 4px;
      background: #7e8090;
    }
  }
}
.card1_top_box {
  position: absolute;
  top: 166px;
  left: 50px;
  right: 50px;
  text-align: center;
  > p:first-of-type {
    font-size: 24px;
    font-weight: bold;
    color: #3c3c3c;
  }
  > p:nth-of-type(2) {
    width: 80px;
    height: 24px;
    margin: 12px auto;
    background: #ffebeb;
    border-radius: 12px;
    font-size: 16px;
    color: #ef622e;
    font-weight: bold;
    text-align: center;
  }
  > div:first-of-type {
    margin-top: 88px;
  }
  > div {
    > div {
      text-align: center;
      margin-bottom: 20px;
      p:first-of-type {
        color: #7e8090;
      }
      p:nth-of-type(2) {
        width: 80px;
        height: 24px;
        line-height: 24px;
        background: #fff;
        border-radius: 12px;
        margin: 4px auto;
        font-weight: bold;
        color: #3c3c3c;
      }
    }
  }
}
#card2 {
  .ab_center {
    p:nth-of-type(1) {
      position: absolute;
      left: 10px;
      right: 10px;
      top: 38px;
      text-align: center;
    }
    p:nth-of-type(2) {
      position: absolute;
      left: 10px;
      right: 10px;
      top: 66px;
      text-align: center;
      font-size: 16px;
    }
  }
}
#card3 .card_main {
  .card3_des {
    flex: 1;
    > div {
      text-align: right;
    }
    .card3_num {
      font-size: 20px;
    }
  }
  .ab_center {
    // left: 61px;
    left: 17.76%;
    right: 286px;
    top: 64px;
    bottom: 28px;
    background: rgba(231, 232, 242, 1);
    text-align: center;
    p:nth-of-type(1) {
      position: absolute;
      left: 0;
      right: 0;
      top: 26px;
    }
    p:nth-of-type(2) {
      position: absolute;
      left: 0;
      right: 0;
      top: 56px;
    }
  }
}
#card5 {
  .content_box {
    text-align: center;
    > div:first-of-type {
      margin-top: 20px;
    }
    > div {
      > p:nth-of-type(1) {
        font-size: 16px;
        color: #7e8090;
      }
      > p:nth-of-type(2) {
        font-size: 56px;
        color: #ef622e;
        .compare {
          font-size: 26px;
        }
      }
    }
  }
}
#card8 {
  .content_box {
    padding: 0 16px;
    > div:first-of-type {
      margin-top: 26px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .name_box {
      font-weight: bold;
      span:nth-of-type(1) {
        color: #3c3c3c;
      }
      span:nth-of-type(2) {
        color: #ef622e;
      }
    }
    .des_box {
      margin-top: 4px;
    }
    .label_box {
      margin-top: 56px;
      > div {
        text-align: center;
        > p:nth-of-type(2) {
          margin-top: 4px;
          color: #3c3c3c;
          width: 80px;
          height: 24px;
          line-height: 24px;
          font-weight: bold;
          border-radius: 12px;
          background: #f5f2f2;
          text-align: center;
        }
      }
    }
  }
}
.move_1,
.move_3 {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(-50%, -50%);
  p {
    padding: 12px 16px;
    background: #7e8090;
    color: #fff;
    font-size: 21px;
    white-space: nowrap;
  }
}
.move4 {
  top: 80%;
}
.large {
  font-size: 56px;
}
#num2 {
  font-size: 56px;
}
#card9 {
  .card_main {
    .title_l {
      span {
        font-size: 12px;
      }
    }
    .title_r {
      > span:nth-of-type(2) {
        color: #c3a178;
        margin-right: 20px;
      }
      > span:nth-of-type(2)::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 2px;
        margin-bottom: 4px;
        margin-right: 4px;
        background: #c3a178;
      }
      > span:nth-of-type(3) {
        color: #636679;
      }
      > span:nth-of-type(3)::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 2px;
        margin-bottom: 4px;
        margin-right: 4px;
        background: #636679;
      }
    }
  }
}
#card10 {
  .content_box {
    text-align: center;
    color: #7e8090;
    > div {
      margin-top: 28px;
      > p:nth-of-type(1) {
        font-size: 16px;
      }
      > p:nth-of-type(2) {
        font-size: 40px;
      }
    }
  }
}
#card12 {
  .content_box {
    > div {
      margin-top: 26px;
    }
    p {
      margin: 8px 16px 0 16px;
    }
  }
}
.card_heng2 {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>